import { Routes } from "@angular/router";
import { AuthGuardService } from "../common-services/authGuard.service";
import { userDataResolver } from "../common-services/user.resolve";
import { googleMapCoreResolver, googleMapDrawingResolver, googleMapMapsResolver, googleMapMarkerResolver, googleMapVisualizationResolver } from "../common-services/map-service/map.resolve";
import { wasmResolver } from "../common-services/wasm_resolver";

export const appRouter: Routes = [
  {
    path: "login",
    loadComponent: () => import("../login/login.component").then(c => c.LoginComponent)
  },
  {
    path: "login/:info",
    loadComponent: () => import("../login/login.component").then(c => c.LoginComponent)
  },
  {
    path: "reset-password",
    loadComponent: () => import("../user-management/reset-password/reset-password.component").then(c => c.ResetPasswordComponent)
  },
  {
    path: "reset-password/new-password/:userId/:resetPasswordToken",
    loadComponent: () => import("../user-management/new-password/new-password.component").then(c => c.NewPasswordComponent)
  },
  {
    path: "activate-user/:userId/:activateUserToken",
    loadComponent: () => import("../user-management/activate-user/activate-user.component").then(c => c.ActivateUserComponent)
  },
  {
    path: "change-email/:userId/:changeEmailToken",
    loadComponent: () => import("../user-management/change-mail/change-mail.component").then(c => c.ChangeMailComponent)
  },
  {
    path: "app",
    loadComponent: () => import("../main/main.component").then(c => c.MainComponent),
    resolve: {
      user: userDataResolver,
      googleMapCore: googleMapCoreResolver,
      googleMapMarker: googleMapMarkerResolver,
      wasm: wasmResolver
    },
    canActivate: [AuthGuardService],
    children: [
      // {
      //   path: "",
      //   redirectTo:"/home",
      //   pathMatch: "full"
      // },
      {
        path: "",
        loadComponent: () => import("../dashboard/dashboard.component").then(c => c.DashboardComponent)
      },
      {
        path: "sites",
        loadComponent: () => import("../site/site.component").then(c => c.SiteComponent),
        resolve: {
          googleMapMaps: googleMapMapsResolver,
          googleMapVisualization: googleMapVisualizationResolver,
          googleMapDrawing: googleMapDrawingResolver
        }
      },
      {
        path: "settings",
        loadComponent: () => import("../settings/settings.component").then(c => c.SettingsComponent),
        resolve: {
          googleMapDrawing: googleMapDrawingResolver
        },
        children: [
          {
            path: "user-settings",
            loadComponent: () => import("../user-settings/user-settings.component").then(c => c.UserSettingsComponent)
          },
          {
            path: "site-manager",
            loadComponent: () => import("../site-manager/site-manager.component").then(c => c.SiteManagerComponent)
          },
          {
            path: "device-manager",
            loadComponent: () => import("../device-manager/device-manager.component").then(c => c.DeviceManagerComponent)
          },
          {
            path: "site-balance-zone-manager",
            loadComponent: () => import("../zone-manager/site-balance-zone-manager.component").then(c => c.SiteBalanceZoneManagerComponent)
          },
          {
            path: "site-network-manager",
            loadComponent: () => import("../network-manager/site-network-manager.component").then(c => c.SiteNetworkManagerComponent)
          },
          {
            path: "network-preference",
            loadComponent: () => import("../network-preference/network-preference.component").then(c => c.NetworkPreferenceComponent)
          },
          {
            path: "site-default-alarms",
            loadComponent: () => import("../site/site-default-alarms/site-default-alarms.component").then(c => c.SiteDefaultAlarmsComponent)
          },
          {
            path: "special-alarms",
            loadComponent: () => import("../special-alarms/special-alarms.component").then(c => c.SpecialAlarmsComponent)
          },
          {
            path: "meas-profile-v2",
            loadComponent: () => import("../measurement-profile-v2/measurement-profile-v2.component").then(c => c.MeasurementProfileV2Component)
          },
          {
            path: "imprint",
            loadComponent: () => import("../imprint/imprint.component").then(c => c.ImprintComponent)
          },
          {
            path: "manage-users",
            loadComponent: () => import("../user-management/user-management.component").then(c => c.UserManagementComponent)
          },
          {
            path: "settings",
            redirectTo: "/account-setting",
            pathMatch: "full"
          }
        ]
      },
      {
        path: "user-manual",
        loadComponent: () => import("../user_manual/user-manual.component").then(c => c.UserManualComponent)
      },
      {
        path: "installation",
        loadComponent: () => import("../nbiot-installation/nbiot-install.component").then(c => c.NBIOTComponent)
      }
    ]
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "**",
    loadComponent: () => import("../404/404.component").then(c => c.NotFoundComponent)
  }
];
