<router-outlet></router-outlet>

<div class="android-prompt prompt" *ngIf="install_prompt_android">
  <div class="prompt-close">
    <button mat-icon-button (click)="close_prompt_android()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="prompt-content">
    <button mat-flat-button class="white-text" (click)="enable_install()">{{'PWA_ADD_TO_HOME_SCREEN' |
      translate}}</button>
  </div>
</div>

<div class="android-prompt prompt" *ngIf="update_prompt">
  <div class="prompt-close">
    <button mat-icon-button (click)="close_prompt_update()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="prompt-content">
    <button mat-flat-button class="white-text" (click)="install_update()">{{'PWA_INSTALL_UPDATE' | translate}}</button>
  </div>
</div>

<div class="ios-prompt prompt" *ngIf="install_prompt_ios">
  <div class="prompt-close">
    <button mat-icon-button (click)="close_prompt_ios()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="prompt-content prompt-ios-info">{{'PWA_INSTALLATION_INFO' | translate}}</div>
</div>